<template>
  <div class='page-container batch-processing flex-column oh'>
    <div class='flex-not-shrink' style='padding: 20px 20px 0;'>
      <div class='mb-1'>
<!--        <el-radio-group v-model='orderType' size='small'>-->
<!--          <el-radio-button :label='1'>校区迁移</el-radio-button>-->
<!--          <el-radio-button class='radio-danger' :label='2'>一键毕业</el-radio-button>-->
<!--        </el-radio-group>-->
      </div>
      <view-search-form
        ref='searchFormRef'
        keyword-placeholder='姓名、学号、录取编号'
        :query-info.sync='tableQuery'
        :rules='rules'
        :tool-list="['grade', 'stuStatus','college','major','keyword']"
        @on-search='renderTable'
        @on-clear='clearTable' />
      <p class='font-grey mt-1' style='font-size: 13px;'>搜索结果: {{ tableData.length }} 人</p>
    </div>
    <!-- body -->
    <div class='flex-column batch-processing__body flex flex-1 oh'>
      <photo-approve-view v-if='orderType===3' :table-data='tableData' />
      <template v-else>
        <el-table :data='tableData' empty-text='请先选择院系、专业' style='width: 100%' height='auto' border stripe
                  @selection-change='handleSelectChange'>
          <el-table-column type='selection' width='50' align='center' />
          <el-table-column type='index' label='ID' width='100' />
          <el-table-column prop='examineNo' label='录取编号' width='90' />
          <el-table-column prop='stuNo' label='学号' width='150' />
          <el-table-column prop='stuName' label='姓名' width='120' />
          <el-table-column label='学籍状态' width='110'>
            <template v-slot='{row}'>
              {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
            </template>
          </el-table-column>
          <el-table-column prop='sexType' label='性别' width='60' align='center'>
            <template v-slot='{row}'>
              {{ Number(row.sexType) === 1 ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column prop='campusName' label='校区' width='150' />
          <el-table-column prop='collegeName' label='院系' width='150' />
          <el-table-column prop='majorName' label='专业' width='150' />
          <el-table-column prop='managerUserName' label='辅导员' width='100' />
          <el-table-column prop='className' label='班级' min-width='150'>
            <template v-slot='{row}'>
              {{ row.className||'未绑定' }}
            </template>
          </el-table-column>
          <el-table-column prop='apartmentName' label='宿舍' min-width='150'>
            <template v-slot='{row}'>
            <span v-if='row.dormitoryName && row.dormitoryBedNo'
            >{{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span>
              <span class='font-grey' v-else-if='row.stuStatus===2'>已毕业</span>
              <span v-else>未分配</span>
            </template>
          </el-table-column>
          <el-table-column prop='remarks' width='200' label='备注' />
        </el-table>
      </template>
    </div>
    <div class='batch-processing__footer flex-not-shrink'>
      <el-button type='danger' v-if='orderType===2' @click='showBatchGradientDialog'>一键毕业
      </el-button>
      <template v-if='orderType===1'>
        <span class='mr-1'>迁移至校区</span>
        <el-select ref='campusRef' class='mr-1' v-model='changeCampusId'>
          <el-option
            v-for='{campusName,id} in campusList'
            :key='id'
            :label='campusName'
            :value='id' />
        </el-select>
        <el-button type='primary' @click='showMoveCampusDialog'>校区迁移
        </el-button>
      </template>
    </div>
    <!-- 毕业操作倒计时 -->
    <confirm-gradient-dialog
      ref='confirmGradientDialogRef'
      :visible.sync='visibleGradientDialog'
      v-if='visibleGradientDialog'
      @on-close='renderTable' />
    <!--  批量迁移校区 确认操作弹窗  -->
    <confirm-move-campus-dialog
      ref='confirmMoveCampusDialogRef'
      :visible.sync='visibleMoveCampusDialog'
      v-if='visibleMoveCampusDialog'
      @on-close='renderTable' />
  </div>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { listStudentApi } from '@/api/education/batch-processing-api'
import ConfirmGradientDialog from '@/views/pages/studentManage/batch-processing/component/confirmGradientDialog.vue'
import ConfirmMoveCampusDialog from '@/views/pages/studentManage/batch-processing/component/confirmMoveCampusDialog.vue'
import PhotoApproveView from '@/views/pages/dormManage/photo-approve/photoApprove.vue'

export default {
  name: 'batchProcessing',
  components: { PhotoApproveView, ConfirmMoveCampusDialog, ConfirmGradientDialog },
  mixins: [tableView],
  data () {
    return {
      campusList: [],
      orderType: 2,
      tableQuery: { // queryInfo
        loadFlag: true,
        collegeId: null,
        keyword: null,
        majorId: null,
        stuStatus: 1, // （0——未注册，1——在读，2——毕业）
        grade: new Date().getFullYear()
      },
      changeCampusId: '', // 批量迁移校区
      visibleMoveCampusDialog: false, // 一键毕业 弹窗显示
      visibleGradientDialog: false, // 一键毕业 弹窗显示
      stuStatusList: this.$store.state.systemParam.stuStatus,
      rowCheckList: [],
      rules: {
        grade: { required: true, message: '请选择年级', trigger: 'blur' },
        majorId: { required: true, message: '请选择专业', trigger: 'blur' }
      }
    }
  },
  mounted () {
    this.getCampusList()
  },
  methods: {
    // 获取学生列表
    async renderTable () {
      this.clearTable()
      if ((!this.tableQuery.majorId || !this.tableQuery.grade) && !this.tableQuery.keyword) {
        this.$message('请先选择专业/年级或者输入学生名后再进行搜索。')
        return
      }
      if (this.tableQuery.keyword && !isNaN(this.tableQuery.keyword) && this.tableQuery.keyword.length < 8) {
        this.$message('学号/考号搜索请最少输入7位数字.')
        return
      }
      this.loading = true

      try {
        const res = await listStudentApi(this.tableQuery)
        this.tableData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // 显示 批量迁移校区的弹窗
    showMoveCampusDialog () {
      // 校验参数合规性
      let isError = false
      let errMsg = ''
      if (Number(this.tableQuery.stuStatus) === 2) {
        isError = true
        errMsg = '不能对已毕业学生进行迁移校区操作'
      } else if (this.rowCheckList.length === 0 && !this.tableQuery.collegeId) {
        isError = true
        errMsg = '请勾选需要迁移校区的学生或者选择学院/专业,以及年级后，再进行批量迁移操作！'
      } else if (!this.changeCampusId) {
        isError = true
        errMsg = '请先选择需要迁移至哪个校区！'
      }
      if (isError) {
        this.$message({
          showClose: true,
          type: 'error',
          message: errMsg
        })
        return
      }
      // 开始批量操作
      let _msg
      const _query = {
        collegeId: null,
        majorId: null,
        grade: null,
        stuStatus: null,
        stuIds: null,
        changeCampusId: null
      }
      if (this.rowCheckList.length) {
        _query.stuIds = [...this.rowCheckList]
        _msg = `选中的 <b class="bg-primary">${_query.stuIds.length}个学生</b> `
      } else {
        ({
          collegeId: _query.collegeId,
          majorId: _query.majorId,
          grade: _query.grade,
          stuStatus: _query.stuStatus
        } = this.tableQuery)
        const _queryLabels = this.getSearchLabels()
        _msg = `将 <b class="primary">${_queryLabels}</b> 的学生`
      }
      _query.changeCampusId = this.changeCampusId
      this.visibleMoveCampusDialog = true
      this.$nextTick(() => {
        this.$refs.confirmMoveCampusDialogRef.formData = _query
        this.$refs.confirmMoveCampusDialogRef.message = `<p class="confirm-message">迁移校区将会清空学生的住宿信息，是否确定将${_msg}批量迁移至校区-<b>${this.$refs.campusRef.selectedLabel}</b>?</p>`
      })
    },
    // 显示 一键毕业的弹窗
    showBatchGradientDialog () {
      const _date = new Date().getFullYear()
      // 校验参数合规性
      let isError = false
      let errMsg = ''
      if (_date === Number(this.tableQuery.grade)) {
        isError = true
        errMsg = `无法 对当年度(${_date})入学的学生进行毕业操作！`
      } else if (Number(this.tableQuery.stuStatus) === 2) {
        isError = true
        errMsg = '不能对已毕业学生再次进行毕业操作'
      } else if (this.rowCheckList.length === 0 && !this.tableQuery.collegeId) {
        isError = true
        errMsg = '请勾选需要毕业的学生或者选择学院/专业后，再进行一键毕业操作！'
      }
      if (isError) {
        this.$message({
          showClose: true,
          type: 'error',
          message: errMsg
        })
        return
      }
      // 开始批量操作
      let _msg
      const _query = {
        collegeId: null,
        majorId: null,
        grade: null,
        stuStatus: null,
        stuIds: null
      }
      if (this.rowCheckList.length) {
        _query.stuIds = [...this.rowCheckList]
        _msg = `选中的 <b class="bg-danger">${_query.stuIds.length} 个</b>`
      } else {
        ({
          collegeId: _query.collegeId,
          majorId: _query.majorId,
          grade: _query.grade,
          stuStatus: _query.stuStatus
        } = this.tableQuery)
        const _queryLabels = this.getSearchLabels()
        _msg = ` <b class="danger">${_queryLabels}</b> 的`
      }
      this.visibleGradientDialog = true
      this.$nextTick(() => {
        this.$refs.confirmGradientDialogRef.formData = _query
        this.$refs.confirmGradientDialogRef.message = `<p class="confirm-message">一键毕业处理过的学生学籍状态更改为“已毕业”，同时将清除宿舍，数据将无法还原。<br/>是否确定将${_msg}学生设置为“<b>已毕业</b>”状态？</p>`
      })
    },
    // 获取校区 list
    getCampusList () {
      if (this.$refs.searchFormRef.campusList.length) {
        this.campusList = this.$refs.searchFormRef.campusList
      } else {
        this.$http.getCampusListQuery().then((res) => {
          this.campusList = res.data
        }).catch()
      }
    },
    // table 多选控制,获取选中的id
    handleSelectChange (val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ id }) => {
          this.rowCheckList.push(id)
        })
      }
    }
  }
}
</script>

<style lang='scss'>
.batch-processing {
  height: calc(100vh - var(--heard-height));
  background: #fff;

  .el-radio-group {
    .el-radio-button.is-active.radio-danger {
      .el-radio-button__inner {
        background: var(--color-danger);
        border-color: var(--color-danger);
        box-shadow: -1px 0 0 0 var(--color-danger);
      }
    }

    .el-radio-button.is-active.radio-success {
      .el-radio-button__inner {
        background: var(--color-success);
        border-color: var(--color-success);
        box-shadow: -1px 0 0 0 var(--color-success);
      }
    }
  }

  .batch-processing__footer {
    padding: 10px 0;
    text-align: center;
  }
}
</style>
