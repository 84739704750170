import request from '@/service/request'

/* 一键操作 学生list接口 */
export const listStudentApi = (params) => {
  return request.post('/stu/oneKeyOperate/list', params)
}

/* 一键操作 一键毕业
* 1.检查是否有选中,有选中的直接传stuIds
* @param {Array} stuIds 学生id 列表
* 2.table无勾选,则按照高级搜索条的参数进行一键毕业操作
* @param {String} collegeId 学院
* @param {String} majorId 专业
* @param {Number} grade 年级
* @param {Number} stuStatus 学籍状态
* */
export const batchGraduationApi = (params) => {
  return request.post('/stu/oneKeyOperate/graduation', params)
}

/* 一键操作 学生一键迁移校区接口
* 1.检查是否有选中,有选中的直接传stuIds
* @param {Array} stuIds 学生id 列表
* 2.table无勾选,则按照高级搜索条的参数进行一键毕业操作
* @param {String} collegeId  学院
* @param {String} majorId  专业
* @param {Number} grade  年级
* @param {Number} stuStatus 学籍状态
* @param {String} changeCampusId 要迁移至的校区id
* */
export const batchMoveCampusApi = (params) => {
  return request.post('/stu/oneKeyOperate/campusChange', params)
}
