<template>
  <el-dialog
    class='confirm-move-campus-dialog'
    title='校区迁移风险确认'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='400px'
    @close='close'>
    <div class='warning-tips' v-html='message'></div>
    <template #footer>
      <ElButton @click='close'>取消</ElButton>
      <ElButton type='primary' :disabled='num>0' @click='save'>确认风险,继续操作{{ num > 0 ? `(${num})` : ''
        }}
      </ElButton>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { batchMoveCampusApi } from '@/api/education/batch-processing-api'

export default {
  name: 'confirmMoveCampusDialog',
  mixins: [dialog],
  props: {},
  data () {
    return {
      loading: false,
      formData: {},
      message: '',
      num: 5
    }
  },
  mounted () {
    setInterval(() => {
      if (this.num > 0) this.num--
    }, 1000)
  },
  methods: {
    async save () {
      this.loading = true
      try {
        await batchMoveCampusApi(this.formData)
        this.$message.success('批量迁移校区操作成功!')
        this.closed()
      } catch (e) {
        this.close()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang='scss'>
.confirm-move-campus-dialog {
  .el-dialog {
    .el-dialog__body {
      padding: 10px 20px 20px;
    }
  }

  .warning-tips {
    line-height: 2;
    font-size: 16px;
  }
}
</style>
