var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container batch-processing flex-column oh"},[_c('div',{staticClass:"flex-not-shrink",staticStyle:{"padding":"20px 20px 0"}},[_c('div',{staticClass:"mb-1"}),_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"姓名、学号、录取编号","query-info":_vm.tableQuery,"rules":_vm.rules,"tool-list":['grade', 'stuStatus','college','major','keyword']},on:{"update:queryInfo":function($event){_vm.tableQuery=$event},"update:query-info":function($event){_vm.tableQuery=$event},"on-search":_vm.renderTable,"on-clear":_vm.clearTable}}),_c('p',{staticClass:"font-grey mt-1",staticStyle:{"font-size":"13px"}},[_vm._v("搜索结果: "+_vm._s(_vm.tableData.length)+" 人")])],1),_c('div',{staticClass:"flex-column batch-processing__body flex flex-1 oh"},[(_vm.orderType===3)?_c('photo-approve-view',{attrs:{"table-data":_vm.tableData}}):[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"empty-text":"请先选择院系、专业","height":"auto","border":"","stripe":""},on:{"selection-change":_vm.handleSelectChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","align":"center"}}),_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"100"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"90"}}),_c('el-table-column',{attrs:{"prop":"stuNo","label":"学号","width":"150"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.sexType) === 1 ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"campusName","label":"校区","width":"150"}}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","width":"150"}}),_c('el-table-column',{attrs:{"prop":"managerUserName","label":"辅导员","width":"100"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className||'未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"apartmentName","label":"宿舍","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName && row.dormitoryBedNo)?_c('span',[_vm._v(_vm._s(row.dormitoryName)+_vm._s(row.roomName)+"室"+_vm._s(row.dormitoryBedNo)+"号床")]):(row.stuStatus===2)?_c('span',{staticClass:"font-grey"},[_vm._v("已毕业")]):_c('span',[_vm._v("未分配")])]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","width":"200","label":"备注"}})],1)]],2),_c('div',{staticClass:"batch-processing__footer flex-not-shrink"},[(_vm.orderType===2)?_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.showBatchGradientDialog}},[_vm._v("一键毕业 ")]):_vm._e(),(_vm.orderType===1)?[_c('span',{staticClass:"mr-1"},[_vm._v("迁移至校区")]),_c('el-select',{ref:"campusRef",staticClass:"mr-1",model:{value:(_vm.changeCampusId),callback:function ($$v) {_vm.changeCampusId=$$v},expression:"changeCampusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":campusName,"value":id}})}),1),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.showMoveCampusDialog}},[_vm._v("校区迁移 ")])]:_vm._e()],2),(_vm.visibleGradientDialog)?_c('confirm-gradient-dialog',{ref:"confirmGradientDialogRef",attrs:{"visible":_vm.visibleGradientDialog},on:{"update:visible":function($event){_vm.visibleGradientDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleMoveCampusDialog)?_c('confirm-move-campus-dialog',{ref:"confirmMoveCampusDialogRef",attrs:{"visible":_vm.visibleMoveCampusDialog},on:{"update:visible":function($event){_vm.visibleMoveCampusDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }